<script setup lang="ts">
import dayjs from 'dayjs';

// Store
const { getConsumptionResponse, getConsumptionError, productionResponse, productionError, loadingProduction, zoomPeriod } =
  storeToRefs(chronologicalStore());
const { setZoomPeriod } = chronologicalStore();
const { getLoading, areaId } = storeToRefs(exploreStore());

// Composables
const { formatNumberToIsoNumber } = useNumbers();
const { options } = useChronologicalChart();
const { t } = useI18n();

// Data
const datasets = ref([]);

// Computed
const timestamps = computed(() => getConsumptionResponse.value?.items.map((item) => dayjs(item.start).toDate()) ?? []);
const production = computed(() => {
  return (
    productionResponse.value?.items.map((item, index) => ({
      id: index,
      start: dayjs(item.production.start).startOf('day').toDate(),
      end: dayjs(item.production.end).startOf('day').add(1, 'day').toDate(),
      title:
        formatNumberToIsoNumber(Number(item.production.quantity)) +
        ` ${areaStore().getArea(areaId.value)?.production_unit?.symbol ?? '--'}`,
    })) ?? []
  );
});
const productionErrorMessage = computed(() => {
  if (!productionError.value) return null;
  return productionError.value?.error_code === 'no_response'
    ? t('error.error_on_get_production')
    : t(`error.${productionError.value?.error_code}`);
});

// Methods
const handlePeriodSelected = ({ start, end }: { start: Date; end: Date }) => {
  setZoomPeriod({ start, end });
};

const handleUnzoom = () => {
  setZoomPeriod(null);
};

watch(
  getConsumptionResponse,
  () => {
    datasets.value = [
      {
        label: 'Consommation',
        data: getConsumptionResponse.value?.items.map((item) => item.avg_power ?? []),
        color: '#1BA774',
      },
    ];
  },
  { deep: true, immediate: true },
);
</script>
<template>
  <nrjx-chart-line
    title="kW"
    :options="options"
    :loading="getLoading"
    :data="{
      labels: timestamps,
      datasets: datasets,
    }"
    :events="production"
    :show-event-bar="productionErrorMessage === null ? true : false"
    :event-loading="loadingProduction"
    :is-zoomed="zoomPeriod !== null"
    @period-selected="handlePeriodSelected"
    @unzoom="handleUnzoom"
  >
    <template #no-data>
      <div v-if="getConsumptionError" class="flex flex-col items-center justify-center h-full">
        <div class="flex flex-col items-center justify-center border border-orange-500 bg-orange-100 rounded-md p-4">
          <ui-icon name="TriangleAlert" class="text-orange-500 mb-4" />
          <p class="text-orange-500 text-sm">{{ $t(`error.${getConsumptionError?.error_code}`) }}</p>
        </div>
      </div>
    </template>
  </nrjx-chart-line>
</template>
