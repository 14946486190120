<script setup lang="ts">
// Components
import ChronologicalChart from '../../components/chart/chronological.vue';
import ChronologicalTable from '../../components/table/chronological.vue';

// Composables
const { getChronologicalConsumption, getProduction } = useConsumptionApi();
const { getKeysFromForage, setKeysInForage } = useExploreStorage();
const { getAggregationLevelFromPeriod } = useExploreFilter();
const { getFiltersFromStorage, setFiltersInStorage } = useChronologicalStorage();
const { t } = useI18n();
const router = useRouter();

// Store
const { getAreaId, getPeriod, getEnergyType, chartType } = storeToRefs(exploreStore());
const { aggregationLevel, zoomPeriod, productionResponse } = storeToRefs(chronologicalStore());
const { setAggregateLevel } = chronologicalStore();

// Data
const watcher = ref();

// Computed
const unitNotFoundError = computed(() => {
  const productionType = productionResponse.value?.items?.at(0)?.production;
  const area = areaStore().getArea(getAreaId.value);
  if (['office'].includes(area?.area_type)) return false;
  if (productionType?.prod_type === 'fabrication_order' && !area?.fabrication_order_unit?.symbol) {
    return t('error.fabrication_order_unit_not_found');
  } else if (productionType?.prod_type === 'production' && !area?.production_unit?.symbol) {
    return t('error.production_unit_not_found');
  } else if (!area?.production_unit?.symbol) {
    return t('error.production_unit_not_found');
  }
  return false;
});

// Methods
const configureUnit = () => {
  router.push({ name: 'area-manage', params: { id: getAreaId.value } });
};

// Lifecycle
onMounted(async () => {
  await getKeysFromForage();
  await getFiltersFromStorage();

  if (chartType.value === 'explore-chronological') {
    watcher.value = watch(
      [() => getAreaId.value, () => getPeriod.value, () => getEnergyType.value, () => aggregationLevel.value, () => zoomPeriod.value],

      async () => {
        await setKeysInForage();
        await setFiltersInStorage();

        const aggregationLevels = getAggregationLevelFromPeriod(zoomPeriod.value ?? getPeriod.value);
        if (!aggregationLevels.includes(aggregationLevel.value)) {
          setAggregateLevel(aggregationLevels[0]);
        } else {
          await getChronologicalConsumption();
          await getProduction();
        }
      },
      { deep: true },
    );
  }

  const aggregationLevels = getAggregationLevelFromPeriod(zoomPeriod.value ?? getPeriod.value);
  if (!aggregationLevels.includes(aggregationLevel.value)) {
    setAggregateLevel(aggregationLevels[0]);
  } else {
    await getChronologicalConsumption();
    await getProduction();
  }
});

onBeforeUnmount(() => {
  watcher.value();
});
</script>
<template>
  <div class="w-full h-full flex flex-col gap-4">
    <div class="flex-1 grid grid-rows-[1fr,auto] gap-4 min-h-0 w-full">
      <ui-info v-if="unitNotFoundError" :message="unitNotFoundError" :link="t('production.configure_unit')" @click-link="configureUnit" />
      <ui-card
        class="w-full h-full min-h-0 overflow-hidden"
        :class="zoomPeriod ? '!border-green-500 ring-offset-0 ring-[1px] ring-green-100' : ''"
      >
        <div class="w-full h-full">
          <chronological-chart />
        </div>
      </ui-card>

      <ui-card class="w-full h-full min-h-0">
        <chronological-table />
      </ui-card>
    </div>
  </div>
</template>
