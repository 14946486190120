import type { Header } from '~/components/ui/data/DataTable.vue';

export const useChronologicalTable = () => {
  // Composables
  const { t } = useI18n();

  const headers: Header[] = [
    {
      key: 'date',
      label: t('explore.consumption.date'),
    },
    {
      key: 'average_power',
      label: t('explore.consumption.average_power'),
    },
    {
      key: 'consumption',
      label: t('explore.consumption.consumption'),
    },
  ];

  return { headers };
};
