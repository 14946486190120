import dayjs from 'dayjs';

export const useConsumptionApi = () => {
  // Composable
  const { client } = useApiV2();

  // Store
  const { getPeriod, getEnergyType, getAreaId } = storeToRefs(exploreStore());
  const { setLoading } = exploreStore();
  const { setConsumptionResponse, setConsumptionError, setProductionResponse, setProductionError, setLoadingProduction } =
    chronologicalStore();
  const { aggregationLevel, zoomPeriod } = storeToRefs(chronologicalStore());

  // Data
  const abortChronologicalController = ref<AbortController | null>(null);
  const currentChronologicalRequestId = ref<string | null>(null);
  const abortProductionController = ref<AbortController | null>(null);
  const currentProductionRequestId = ref<string | null>(null);

  // Methods
  // ============ Get Consumption for one area ============
  const getChronologicalConsumption = async () => {
    const requestId = dayjs().toString();
    currentChronologicalRequestId.value = requestId;

    const start = zoomPeriod.value
      ? dayjs(zoomPeriod.value.start).format('YYYY-MM-DD HH:mm')
      : dayjs(getPeriod.value.start).format('YYYY-MM-DD HH:mm');
    const end = zoomPeriod.value
      ? dayjs(zoomPeriod.value.end).format('YYYY-MM-DD HH:mm')
      : dayjs(getPeriod.value.end).format('YYYY-MM-DD HH:mm');

    if (abortChronologicalController.value) {
      abortChronologicalController.value.abort();
    }

    try {
      abortChronologicalController.value = new AbortController();
      setConsumptionResponse(null);
      setConsumptionError(null);
      setLoading(true);

      const reponse = await client.GET('/v2/areas/{area_id}/explore/consumptions', {
        params: {
          query: {
            energy_type: getEnergyType.value,
            start: start,
            end: end,
            aggregation_level: aggregationLevel.value,
          },
          path: {
            area_id: getAreaId.value,
          },
        },
        signal: abortChronologicalController.value?.signal,
      });

      if (reponse.data.data) {
        setConsumptionResponse(reponse.data.data);
      } else {
        setConsumptionError(reponse.data.error);
        setConsumptionResponse(null);
      }
      setLoading(false);
    } catch (error) {
      if (error.name !== 'AbortError') {
        setConsumptionResponse(null);
        setConsumptionError({
          message: error.message,
          status_code: 500,
          error_code: 'no_response',
        });
        setLoading(false);
        throw error;
      }
    }
  };

  const getProduction = async () => {
    const requestId = dayjs().toString();
    currentProductionRequestId.value = requestId;

    const start = zoomPeriod.value
      ? dayjs(zoomPeriod.value.start).format('YYYY-MM-DD HH:mm')
      : dayjs(getPeriod.value.start).format('YYYY-MM-DD HH:mm');
    const end = zoomPeriod.value
      ? dayjs(zoomPeriod.value.end).format('YYYY-MM-DD HH:mm')
      : dayjs(getPeriod.value.end).format('YYYY-MM-DD HH:mm');

    if (abortProductionController.value) {
      abortProductionController.value.abort();
    }

    try {
      abortProductionController.value = new AbortController();
      setProductionResponse(null);
      setProductionError(null);
      setLoadingProduction(true);

      const reponse = await client.GET('/v2/areas/{area_id}/explore/productions', {
        params: {
          query: {
            energy_type: getEnergyType.value,
            start: start,
            end: end,
          },
          path: {
            area_id: getAreaId.value,
          },
        },
        signal: abortProductionController.value?.signal,
      });

      if (reponse.data.data) {
        setProductionResponse(reponse.data.data);
        setProductionError(null);
      } else {
        setProductionError(reponse.data.error);
        setProductionResponse(null);
      }
    } catch (error) {
      setLoadingProduction(false);
      setProductionResponse(null);
      setProductionError({
        message: error.message,
        status_code: 500,
        error_code: 'no_response',
      });
      if (error.name !== 'AbortError') {
        throw error;
      }
    }
    setLoadingProduction(false);
  };

  return { getChronologicalConsumption, getProduction };
};
