import localforage from 'localforage';
import type { AggregationLevels } from '../../types';

export const useChronologicalStorage = () => {
  // Stores
  const { selectedClient, selectedSite } = storeToRefs(globalStore());
  const { getPeriod } = storeToRefs(exploreStore());
  const { aggregationLevel, zoomPeriod } = storeToRefs(chronologicalStore());
  const { setAggregateLevel, setZoomPeriod } = chronologicalStore();

  // Composables
  const { getAggregationLevelFromPeriod } = useExploreFilter();

  const getFiltersFromStorage = async () => {
    // Aggregation levels
    const aggregationLevels = getAggregationLevelFromPeriod(getPeriod.value);
    const aggregationLevelFromStorage = await localforage.getItem<AggregationLevels>(
      `nrjx-${selectedClient.value}-${selectedSite.value[0]}-chronological-aggregation-level`,
    );

    if (!aggregationLevels.includes(aggregationLevelFromStorage)) {
      setAggregateLevel(aggregationLevels[0]);
    } else {
      setAggregateLevel(aggregationLevelFromStorage);
    }

    // Zoom period
    const zoomPeriodFromStorage = await localforage.getItem<string>(
      `nrjx-${selectedClient.value}-${selectedSite.value[0]}-chronological-zoom-period`,
    );
    const zoomPeriodFromStorageParsed = JSON.parse(zoomPeriodFromStorage);
    setZoomPeriod(zoomPeriodFromStorageParsed ?? null);
  };

  const setFiltersInStorage = () => {
    localforage.setItem(`nrjx-${selectedClient.value}-${selectedSite.value[0]}-chronological-aggregation-level`, aggregationLevel.value);
    localforage.setItem(
      `nrjx-${selectedClient.value}-${selectedSite.value[0]}-chronological-zoom-period`,
      JSON.stringify(zoomPeriod.value),
    );
  };

  return {
    getFiltersFromStorage,
    setFiltersInStorage,
  };
};
