<script setup lang="ts">
import dayjs from 'dayjs';

// Composables
const { formatNumberToIsoNumber } = useNumbers();
const { getConsumptionDataList, getConsumptionOptionList } = useGlobalFilter();
const { headers } = useChronologicalTable();

// Store
const { getConsumptionResponse } = storeToRefs(chronologicalStore());
const { getMeterId, loading } = storeToRefs(exploreStore());
const { aggregationLevel } = storeToRefs(chronologicalStore());

// Data
const seletedConsumptionUnit = ref<keyof ReturnType<typeof getConsumptionDataList>>('quantity');

// Methods
const getFormatedDate = (date: string) => {
  const dateParsed = dayjs(date);
  switch (aggregationLevel.value) {
    case 'hour':
      return dateParsed.format('HH:mm');
    case 'day':
      return dateParsed.format('DD/MM/YYYY');
    case 'week':
      const start = dateParsed.startOf('week');
      const end = dateParsed.endOf('week');
      return `${start.format('DD/MM/YYYY')} - ${end.format('DD/MM/YYYY')}`;
    case 'month':
      return dateParsed.format('MM/YYYY');
    default:
      return dateParsed.format('DD/MM/YYYY');
  }
};
</script>
<template>
  <ui-data-table :items="getConsumptionResponse?.items ?? []" :headers="headers" :loading="loading">
    <!-- Header -->
    <template #header-consumption>
      <div class="flex items-center gap-2">
        <nrjx-input-select
          v-model="seletedConsumptionUnit"
          name="consumption"
          :leading-text="$t('global.consumption')"
          item-key="key"
          item-label="label"
          size="xs"
          class="w-[200px]"
          :content="{ direction: 'bottom', align: 'end', sideOffset: 4 }"
          :items="getConsumptionOptionList"
        />
      </div>
    </template>

    <!-- Body -->
    <template #item-date="{ item }"> {{ getFormatedDate(item.start) }} </template>
    <template #item-consumption="{ item }">
      {{
        getConsumptionDataList({
          consumption: item.consumption.quantity,
          cost: Number(item.consumption.cost),
          meterId: getMeterId,
        })[seletedConsumptionUnit]
      }}
    </template>
    <template #item-average_power="{ item }"> {{ formatNumberToIsoNumber(item.avg_power) }} kW </template>
  </ui-data-table>
</template>
