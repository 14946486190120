// import type { ChartOptions } from 'chart.js';
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import isoWeek from 'dayjs/plugin/isoWeek';
import type { TooltipData } from '~/components/nrjx/chart/tooltip/index.vue';
import type { ChartLineOptions } from '~/components/nrjx/chart/line.vue';

dayjs.extend(isoWeek);

export const useChronologicalChart = () => {
  // Composables
  const { t } = useI18n();
  const { formatNumberToIsoNumber } = useNumbers();

  // Stores
  const { getConsumptionResponse, productionResponse } = storeToRefs(chronologicalStore());
  const { areaId } = storeToRefs(exploreStore());
  const { aggregationLevel } = storeToRefs(chronologicalStore());
  const { getFormattedDateFromAggregateLevel } = useChart();

  const options = ref<ChartLineOptions>({
    x: {
      ticks: {
        callback: (value: number) => {
          return getFormattedDateFromAggregateLevel(getConsumptionResponse.value?.items[value].start, aggregationLevel.value);
        },
      },
    },
    tooltip: (context) => {
      const data = getConsumptionResponse.value.items[context[0].index];
      const tooltipData: TooltipData = {
        title: t('gloabl.power'),
        subtitle: `${formatNumberToIsoNumber(data.avg_power, 2)} kW`,
        body: [
          { title: t('global.consumption'), value: `${formatNumberToIsoNumber(Number(data.consumption.quantity))} kWh` },
          { title: t('global.cost'), value: `${formatNumberToIsoNumber(Number(data.consumption.cost))} €` },
        ],
        footer: getFormattedDateFromAggregateLevel(data.start, aggregationLevel.value),
      };
      return tooltipData;
    },
    eventTooltip: (index) => {
      // Variables
      const data = productionResponse.value.items[index];
      const area = areaStore().getArea(areaId.value);
      const start = dayjs(data.production.start);
      const end = dayjs(data.production.end);
      const date = start.isSame(end, 'day') ? start.format('DD/MM/YYYY') : `${start.format('DD/MM/YYYY')} - ${end.format('DD/MM/YYYY')}`;
      const canAddProduction = computed(() => {
        if (
          productionStore().getProductionPlan?.plan_type === 'FabricationOrderLookup' ||
          !productionStore().getProductionPlan?.plan_type
        ) {
          return false;
        }
        return true;
      });

      return {
        title: t('global.epi'),
        subtitle: `${formatNumberToIsoNumber(Number(data.consumption_per_unit.quantity), 2)} kWh/${area?.production_unit?.symbol ?? '--'}`,
        body: [
          {
            title: t('global.quantity'),
            value: `${formatNumberToIsoNumber(Number(data.production.quantity))} ${area?.production_unit?.symbol ?? '--'}`,
          },
          { title: t('global.power'), value: `${formatNumberToIsoNumber(Number(data.avg_power), 2)} kW` },
        ],
        footer: date,
        description: canAddProduction.value ? t('explore.click_to_edit_production') : null,
      };
    },
  });

  return {
    options,
  };
};
